<template>
  <DashboardAdminTemplate>
    <div class="pd-x-5" v-loading.fullscreen.lock="loading" element-loading-text="Loading...">
      <el-row>
        <el-col :span="8">
          <div class="is-flex ai-center">
            <h2 class="mg-b-5">รายการไฟล์ Export ทั้งหมด</h2>
          </div>
          <div class="is-flex mg-b-3">
            <router-link :to="{ path: '/user/list' }" class="color-grey-light"
              >จัดการข้อมูลเพื่อการวิเคราะห์
            </router-link>
            <p class="mg-y-0 color-grey-light mg-x-5 font-20">></p>
            <p class="mg-y-0 color-primary font-20">
              รายการไฟล์ Export ทั้งหมด
            </p>
          </div>
        </el-col>
        <el-col :span="24" :offset="0">
          <el-table border :data="fileList" style="width: calc(100% - 5px)">
            <template slot="empty">
              <div class="noData">ไม่พบข้อมูล</div>
            </template>
            <el-table-column prop="filename" label="ไฟล์" align="center" header-align="center">
            </el-table-column>
            <el-table-column prop="name" label="เจ้าหน้าที่ดาวน์โหลด" align="center" header-align="center">
            </el-table-column>
            <el-table-column prop="name" label="เวลาที่จัดเตรียมไฟล์" align="center" header-align="center">
              <template slot-scope="scope">
                {{ scope.row.createdAt | dateTimeTh }}
              </template>
            </el-table-column>
            <el-table-column prop="name" label="สถานะดาวน์โหลดไฟล์" align="center" header-align="center">
              <template slot-scope="scope">
                <div class="text-orange" v-if="scope.row.status == 0">
                  กำลังจัดเตรียมไฟล์...
                </div>
                <div v-if="scope.row.status == 1" class="color-success">
                  ไฟล์พร้อมดาวน์โหลด
                </div>
                <div class="text-grey-light" v-if="scope.row.status == 3">
                  ดาวน์โหลดไม่สำเร็จ
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="เวลาที่ไฟล์พร้อมดาวน์โหลด" align="center" header-align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.status != 0">
                  {{ scope.row.updatedAt | dateTimeTh }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="" align="center" header-align="center">
              <template slot-scope="scope">
                <div>
                  <a target="_blank" class="mg-r-3" :href="
                    scope.row.status == '1'
                      ? linkFile + scope.row.filename
                      : '#'
                  ">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="18" height="28"
                      :fill="scope.row.status == 1 ? '#07509C' : '#C4C4C4'">
                      <path
                        d="M384 128h-128V0L384 128zM256 160H384v304c0 26.51-21.49 48-48 48h-288C21.49 512 0 490.5 0 464v-416C0 21.49 21.49 0 48 0H224l.0039 128C224 145.7 238.3 160 256 160zM255 295L216 334.1V232c0-13.25-10.75-24-24-24S168 218.8 168 232v102.1L128.1 295C124.3 290.3 118.2 288 112 288S99.72 290.3 95.03 295c-9.375 9.375-9.375 24.56 0 33.94l80 80c9.375 9.375 24.56 9.375 33.94 0l80-80c9.375-9.375 9.375-24.56 0-33.94S264.4 285.7 255 295z" />
                    </svg>
                  </a>
                  <a @click="deleteFileDownload(scope.row)">
                    <svg width="18" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="#C4C4C4">
                      <path
                        d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM31.1 128H416V448C416 483.3 387.3 512 352 512H95.1C60.65 512 31.1 483.3 31.1 448V128zM111.1 208V432C111.1 440.8 119.2 448 127.1 448C136.8 448 143.1 440.8 143.1 432V208C143.1 199.2 136.8 192 127.1 192C119.2 192 111.1 199.2 111.1 208zM207.1 208V432C207.1 440.8 215.2 448 223.1 448C232.8 448 240 440.8 240 432V208C240 199.2 232.8 192 223.1 192C215.2 192 207.1 199.2 207.1 208zM304 208V432C304 440.8 311.2 448 320 448C328.8 448 336 440.8 336 432V208C336 199.2 328.8 192 320 192C311.2 192 304 199.2 304 208z" />
                    </svg>
                  </a>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="mg-y-3">
            <div class="block text-center">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page.sync="currentPage" :page-sizes="[15, 30, 50, 100, 500]" :page-size="15"
                layout="total, sizes, prev, pager, next, jumper" :total="totalFile">
              </el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </DashboardAdminTemplate>
</template>

<script>
import DashboardAdminTemplate from "@/template/DashboardAdminTemplate";
import { HTTP } from "@/service/axios";
export default {
  data ()
  {
    return {
      currentPage: 1,
      loading: false,
      fileList: [],
      totalFile: 0,
      linkFile: process.env.VUE_APP_IMAGE_SYSTEM_URL,
      page: 1,
      filter: {
        limit: 15,
        skip: 0
      }
    };
  },
  components: {
    DashboardAdminTemplate
  },
  mounted ()
  {
    this.getFileList();
  },
  methods: {
    async handleCurrentChange ( val )
    {
      if ( val == 1 )
      {
        this.filter.skip = 0;
      } else
      {
        this.filter.skip = ( val - 1 ) * this.filter.limit;
      }
      this.getFileList();
    },
    async handleSizeChange ( val )
    {
      this.filter.limit = val;
      this.handleCurrentChange( 1 );
    },
    async deleteFileDownload ( data )
    {
      this.$confirm(
        "ยืนยันการลบไฟล์ " + data.filename + " จะลบออกจากระบบถาวร",
        "ยืนยันการลบข้อมูล",
        {
          confirmButtonText: "ตกลง",
          cancelButtonText: "ยกเลิก",
          type: "warning"
        }
      )
        .then( () =>
        {
          this.loading = true;
          HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          HTTP.delete(`logs/export/${data.id}`)
            .then(res => {
              if (res.data.success) {
                this.handleCurrentChange(1);
              } else {
                this.alertFailError();
                this.loading = false;
              }
            })
            .catch(e => {
              if (e == "Error: Request failed with status code 401") {
                this.checkAuth();
              } else
              {
                if ( e != "Error: timeout of 120000ms exceeded" )
                {
                  this.alertCatchError( e );
                }
              }
              this.loading = false;
              console.log( "delUser error", e );
            } );
          this.$message( {
            type: "success",
            message: "ลบข้อมูลสำเร็จ"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "ยกเลิกการลบ"
          });
          this.loading = false;
        } );
    },
    async getFileList ()
    {
      try
      {
        this.loading = true;
        HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
        HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
        await HTTP.post(`logs/all`, this.filter)
          .then(result => {
            if (result.data.success) {
              this.loading = false;
              this.fileList = result.data.obj;
              this.totalFile = result.data.total;
            } else
            {
              this.loading = false;
            }
          })
          .catch(e => {
            this.loading = false;
            if ( e == "Error: Request failed with status code 401" )
            {
              this.checkAuth();
            } else
            {
              if ( e != "Error: timeout of 120000ms exceeded" )
              {
                this.alertCatchError( e );
              }
            }
          } );
      } catch ( error )
      {
        console.log( "getFileList catch", error );
      }
    }
  }
};
</script>

<style>

</style>